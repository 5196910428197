import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/markdown-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`RETURN POLICY`}</h1>
    <p>{`The User may withdraw from the Agreement within 14 days from the date of payment for the service / purchase of the ticket, no later than the starting day of the conference/event,
by submitting a written notice of withdrawal to e-mail: `}<a parentName="p" {...{
        "href": "mailto:tickets@armada-js.com"
      }}>{`tickets@armada-js.com`}</a></p>
    <p>{`The request can't be made after the conference starts.`}</p>
    <p>{`In case of withdrawal from the Agreement, the User shall be refunded within 30 days from the day of receiving the notice of withdrawal.`}</p>
    <p>{`In the event of not performing the service or cancelling the event for any reason, Armada Konferencije doo will notify the User as soon as possible and make refund.
Armada Konferencije doo will not be liable for any form of damages suffered by the User due to the cancellation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      